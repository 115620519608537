import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";

import { Contact } from "./components/contact";
import Home from "./pages/Home";
import Impdat  from "./pages/Impdat";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Route, Routes, Switch, Link } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  
  return (
    <div>
      <ScrollToTop />
      <Navigation />
      <div>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/datenschutzerklaerung" element={<Impdat/>} />
        </Routes>
      </div>
      
    </div>
  );
};

export default App;
