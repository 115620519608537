import { Impressum } from '../components/impressum'
import { motion } from "framer-motion/dist/framer-motion"

const Impdat = () => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Impressum />
    </motion.div>
  )
}

export default Impdat