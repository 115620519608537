import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';


export const Navigation = (props) => {
  const [expanded, SetExpanded] = useState(false)

  const setToggle = () => {
    console.log('toggle');
    SetExpanded(true)
  }
  return (
    
    <nav OnToggle={setToggle} id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>

          <Link smooth to='' className='navbar-brand page-scroll' >
            <img onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} src="img/logo-black.png" alt="" />
          </Link>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <Link smooth to="/#features" className='page-scroll'>Leistungen</Link>
            </li>
            <li>
              <Link smooth to="/#pricetable" className='page-scroll'>Preise</Link>
            </li>
            <li>
              <Link smooth to="#contact" >Anfrage</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
