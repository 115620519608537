export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>LEISTUNGEN</h2>
          <p>
            Das Palettenhostel bietet Ihnen professionelle und sichere
            Lagerungslösungen. Wir stellen unseren Kunden 3000+
            Palettenstellplätze in unseren trockenen, sauberen und sicheren
            Lagerhallen zur Verfügung. Unsere Lagerhallen in Bornheim bei Bonn sind
            beheizt und alarmgesichert. Zudem sind wir direkt an der A555
            Ausfahrt Bornheim bequem und schnell erreichbar. 
            <br/> 
            <br/> 
            Wir bieten unseren
            Kunden flexible Mietoptionen an, sodass sie bei uns entweder
            langfristig oder kurzfristig mieten können. Darüber hinaus können
            unsere Kunden die Größe ihres Palettenstellplatzes je nach Bedarf
            anpassen. Unser Ziel ist es, unseren Kunden die bestmögliche
            Lagerungslösung zu bieten, die perfekt auf ihre Bedürfnisse
            zugeschnitten ist. Unsere Lager sind mit modernster Technologie
            ausgestattet, um eine optimale Lagerung von Waren zu gewährleisten.
            <br/>
            <br/>
            Palettenhostel ist Ihr zuverlässiger Partner für die Lagerung Ihrer
            Waren. Wir garantieren unseren Kunden eine schnelle und
            unkomplizierte Abwicklung sowie einen professionellen und
            zuvorkommenden Kundenservice.
          </p>
        </div>
      </div>
    </div>
  );
};
