import React, { useState } from "react";

export const PriceTable = (props) => {
  const handlingCostsData = [
    {
      id: 1,
      name: "1 bis 50 Paletten",
      spalten: { 1: "135cm", 2: "300Kg", 3: "55,00 €" },
      items: [
        { name: "135cm", weight: "300Kg", cost: "45,00 €" },
        { name: "155cm", weight: "400Kg", cost: "50,00 €" },
        { name: "175cm", weight: "500Kg", cost: "55,00 €" },
        { name: "185cm", weight: "600Kg", cost: "60,00 €" },
        { name: "200cm", weight: "700Kg", cost: "75,00 €" },
      ],
      extra: "12 Monate Vertragsdauer",
    },
    {
      id: 2,
      name: "51 bis 250 Paletten",
      items: [
        { name: "135cm", weight: "300Kg", cost: "40,00 €" },
        { name: "155cm", weight: "400Kg", cost: "45,00 €" },
        { name: "175cm", weight: "500Kg", cost: "50,00 €" },
        { name: "185cm", weight: "600Kg", cost: "55,00 €" },
        { name: "200cm", weight: "700Kg", cost: "70,00 €" },
      ],
      extra: "12 Monate Vertragsdauer",
    },
    {
      id: 3,
      name: "ab 251 Paletten",
      items: [
        { name: "135cm", weight: "300Kg", cost: "35,00 €" },
        { name: "155cm", weight: "400Kg", cost: "40,00 €" },
        { name: "175cm", weight: "500Kg", cost: "45,00 €" },
        { name: "185cm", weight: "600Kg", cost: "50,00 €" },
        { name: "200cm", weight: "700Kg", cost: "65,00 €" },
      ],
      extra: "12 Monate Vertragsdauer",
    },
    {
      id: 4,
      name: "beliebige Anzahl Paletten",
      items: [
        { name: "135cm", weight: "300Kg", cost: "55,00 €" },
        { name: "155cm", weight: "400Kg", cost: "65,00 €" },
        { name: "175cm", weight: "500Kg", cost: "75,00 €" },
        { name: "185cm", weight: "600Kg", cost: "85,00 €" },
        { name: "200cm", weight: "1000Kg", cost: "120,00 €" },
      ],
      extra: "keine Vertragsdauer, monatlich kündbar",
    },
    {
      id: 5,
      name: "Handlingskosten je Europalette",
      items: [
        { name: "Einlagerung auf Stellplatz",  cost: "15,00 €" },
        { name: "Auslagerung vom Stellplatz", cost: "15,00 €" },
        { name: "Frachtpapiere prüfen",  cost: "15,00 €" },
        { name: "Frachtpapiere Ausgang übergaben / einladen", cost: "15,00 €" },
        { name: "Etikette mit einmaliger Paletten ID", cost: "3 €" },
        { name: "Abholung/Lieferung", cost: "auf Anfrage" },
      ],
      extra: "",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  // Define the function to handle tab clicks
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div id="pricetable" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>PREISE</h2>
          <div>
            <div className="tab">
              {handlingCostsData.map((data, index) => (
                <button
                  key={data.id}
                  className={`tab-button ${
                    index === activeTab ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {data.name}
                </button>
              ))}
            </div>
            {handlingCostsData.map((data, index) => (
              <div
                key={data.id}
                className={`tab-content ${index === activeTab ? "active" : ""}`}
              >
                <table>
                  <thead>
                  {index !== 4 && (
                    <tr>
                      <th>Palettenhöhe inkl. Holzpalette max. Höhe</th>
                      <th>Palettengewicht bis zu</th>
                      <th>Mietkosten je angefangener Monat</th>
                    </tr>
                  )}
                  </thead>
                  <tbody>
                    {data.items.map((item) => (
                      <tr key={item.name}>
                        <td>{item.name}</td>
                        <td>{item.weight}</td>
                        <td>{item.cost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>
                  <br/> {data.extra}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
