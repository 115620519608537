export const Impressum = () => {
  return (
    <div id="impressum">
      <div className="container">
        <div className="about-text">
          <h2>Datenschutzrechtliche Bestimmungen</h2>

          <h3>Übersicht</h3>

          <h4>Datenarten</h4>

          <p>
            <strong>Zur Account-Nutzung: </strong>
          </p>

          <p>In-App (Security &amp; Technische N.):</p>

          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li> Sprache und Version der Browsersoftware</li>
          </ul>

          <p>
            <strong>Weitergabe von Daten:</strong>
            <br />
            Weitergabe an den IT Dienstleister der Webseite
          </p>

          <p>Weitergabe zu Zwecken der Buchung und Vertragsabschlüssen</p>

          <p>
            <strong>Cloudnutzung:</strong>
            <br />
            keine
          </p>

          <p>
            <strong>Tracking:</strong>
            <br />
            Anonyme Nutzerprofile über Google Analytics
          </p>

          <p>
            <strong>Werbung:</strong>
            <br />
            Im gesetzlichen Rahmen bei gleichartigen Produkten per E-Mail
          </p>

          <p>Weitergabe zum Zweck von Aktionen und Gewinnspielen</p>

          <h3>
            § 1 Information über die Erhebung personenbezogener Daten und
            Anbieterkennzeichnung
          </h3>

          <ol>
            <li>
              Im Folgenden informieren wir über die Erhebung personenbezogener
              Daten bei Nutzung dieser Website. Personenbezogene Daten sind alle
              Daten, die auf Sie persönlich beziehbar sind, also z. B. Name,
              Adresse, E-Mail-Adressen, Nutzerverhalten.
            </li>
            <li>
              Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
              (DS-GVO) ist
              <br />
              <div class="view view-filialen view-id-filialen view-display-id-attachment_3 view-dom-id-c2256c94c76a8cc873461ec79b4c3e5b">
                <div class="view-content">
                  <h5>PalettenHostel</h5>
                  <address>
                    <div class="street-block">
                      <div class="thoroughfare">Alexander-Bell-Straße 3</div>
                    </div>
                    <div class="addressfield-container-inline locality-block country-DE">
                      <span class="postal-code">53332</span>{" "}
                      <span class="locality">Bornheim</span>
                    </div>
                    <span class="country">Deutschland</span>
                  </address>{" "}
                </div>
              </div>
              <div class="view view-filialen view-id-filialen view-display-id-attachment_4 view-dom-id-17169a2422f91f52b55bfa3a304baeb0">
                <div class="view-content">
                  <a href="mailto:info@palettenhostel.de">
                    info@palettenhostel.de
                  </a>{" "}
                </div>
              </div>{" "}
              (siehe unser Impressum).
              <br />
            </li>
            <li>
              Falls wir für einzelne Funktionen unseres Angebots auf beauftragte
              Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke
              nutzen möchten, werden wir Sie untenstehend im Detail über die
              jeweiligen Vorgänge informieren. Dabei nennen wir auch die
              festgelegten Kriterien der Speicherdauer.
            </li>
          </ol>

          <h3>§ 2 Rechte, insbesondere auf Auskunft und Widerruf</h3>

          <ol>
            <li>
              Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
              betreffenden personenbezogenen Daten:
              <ul>
                <li>Recht auf Auskunft,</li>
                <li>Recht auf Berichtigung oder Löschung,</li>
                <li>Recht auf Einschränkung der Verarbeitung,</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>Recht auf Datenübertragbarkeit.</li>
              </ul>
            </li>
            <li>
              Wenn Sie eine Einwilligung zur Nutzung von Daten erteilt haben,
              können Sie diese jederzeit widerrufen. Sollte die Rechtmäßigkeit
              der Verarbeitung auf Grundlage einer Einwilligung bestehen, bleibt
              diese bis zur Ausübung des Widerrufs gültig.
            </li>
            <li>
              Alle Informationswünsche, Auskunftsanfragen oder Widersprüche zur
              Datenverarbeitung richten Sie bitte per E-Mail an{" "}
              <div class="view view-filialen view-id-filialen view-display-id-attachment_4 view-dom-id-17169a2422f91f52b55bfa3a304baeb0">
                <div class="view-content">
                  <a href="mailto:info@palettenhostel.de">
                    info@palettenhostel.de
                  </a>{" "}
                </div>
              </div>{" "}
              oder an die unter § 1 Abs. 2 genannte Adresse.
            </li>
            <li>
              Sie können von uns jederzeit verlangen ihre Daten zu löschen.
              Hierbei können gesetzliche Aufbewahrungsfristen bestehen, die es
              uns erlauben ihre Daten bis zum Ablauf der Frist aufzubewahren.
            </li>
            <li>
              Wenn ihre Daten unrichtig sein sollten, haben sie das Recht uns zu
              einer Berichtigung aufzufordern. Dieser Aufforderung kommen wird
              unverzüglich nach.
            </li>
            <li>
              Sie haben das Recht ihre uns zur Verfügung gestellten
              personenbezogenen Daten, von uns in einem lesbaren Format zu
              erhalten, soweit technisch möglich, um Sie einem anderen
              Unternehmen zur Verfügung zu stellen (Recht auf
              Datenübertragbarkeit).
            </li>
            <li>
              Sie haben das Recht sich bei der für Sie zuständigen
              Aufsichtsbehörde zu beschweren.
            </li>
          </ol>

          <h3>§ 3 Datensicherheit</h3>

          <ol>
            <li>
              Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung
              der Datensicherheit, insbesondere zum Schutz Ihrer
              personenbezogenen Daten vor Gefahren bei Datenübertragungen sowie
              vor Kenntniserlangung durch Dritte. Diese werden dem aktuellen
              Stand der Technik entsprechend jeweils angepasst.{" "}
            </li>
          </ol>

          <h3>
            § 4 Erhebung personenbezogener Daten bei informatorischer Nutzung
            und Kontaktaufnahme
          </h3>

          <ol>
            <li>
              Bei der bloß informatorischen Nutzung der Website, also wenn Sie
              sich nicht registrieren oder uns anderweitig Informationen
              übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
              Browser an unseren Server übermittelt. Wenn Sie unsere Website
              betrachten möchten, erheben wir die folgenden Daten, die für uns
              technisch erforderlich sind, um Ihnen unsere Website anzuzeigen
              und die Stabilität und Sicherheit zu gewährleisten
              (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
              <ul>
                <li>–IP-Adresse</li>
                <li>–Datum und Uhrzeit der Anfrage</li>
                <li>–Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>–Inhalt der Anforderung (konkrete Seite)</li>
                <li>–Zugriffsstatus/HTTP-Statuscode</li>
                <li>–jeweils übertragene Datenmenge</li>
                <li>–Website, von der die Anforderung kommt</li>
                <li>–Browser</li>
                <li>–Betriebssystem und dessen Oberfläche</li>
                <li>- Sprache und Version der Browsersoftware</li>
              </ul>
            </li>
            <li>
              Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über das
              Kontaktformular [MG4] wird Ihre E-Mail-Adresse, ihr Name und,
              falls Sie dies angeben, ihre Telefonnummer von uns gespeichert.
              Der Zweck dieser Speicherung ist lediglich die Kontaktaufnahme, um
              Ihre Fragen zu beantworten.
            </li>
            <li>
              Rechtsgrundlage der angegebenen Erhebung ist die Einwilligung, die
              sie durch Besuch unserer Webseite und Bestätigen des
              Cookie-Banners kundgetan haben (Art. 6 Abs. 1 lit. a DSGVO).
            </li>
            <li>
              Wir werden ihre Daten nur gesetzlich im erlaubten Umfang zum
              Zwecke der Werbung nutzen. Insbesondere nutzen wir ihre E-Mail
              Adresse lediglich zur Direktwerbung für eigene ähnliche Waren oder
              Dienstleistungen. Sie können der Verwendung ihrer Daten zu
              Werbezwecken jederzeit schriftlich oder in Textform (E-Mail an{" "}
              <div class="view view-filialen view-id-filialen view-display-id-attachment_4 view-dom-id-17169a2422f91f52b55bfa3a304baeb0">
                <div class="view-content">
                  <a href="mailto:info@palettenhostel.de">
                    info@palettenhostel.de
                  </a>{" "}
                </div>
              </div>
              ) widersprechen. Hierbei berufen wir uns auf unser berechtigtes
              Interesse an der Bewerbung unserer Produkte gegenüber unseren
              Kunden gem. Art. 6 Abs. 1 lit. f DSGVO.
            </li>
            <li>
              Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
              unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
              handelt es sich um kleine Textdateien, die auf Ihrer Festplatte
              dem von Ihnen verwendeten Browser zugeordnet gespeichert werden
              und durch welche der Stelle, die den Cookie setzt (hier durch
              uns), bestimmte Informationen zufließen. Cookies können keine
              Programme ausführen oder Viren auf Ihren Computer übertragen. Sie
              dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und
              effektiver zu machen.
            </li>
            <li>
              Sofern Ihre Einwilligung gem. Art 6 Abs. 1 lit. a DSGVO vorliegt
              werden wir ihre Daten zum Zweck von Aktionsteilnahmen,
              Gewinnspielen, Buchungen oder Vertragsabschlüssen an Drittanbieter
              weitergeben.
            </li>
          </ol>

          <h3>§ 5 Cookies</h3>

          <ol>
            <li>
              Wir verwenden auf unserer Website Cookies. Solche Cookies sind
              notwendig, damit Sie sich auf der Website frei bewegen und deren
              Features nutzen können; hierzu gehört u. a. auch der Zugriff auf
              gesicherte Bereiche der Website. Durch Cookies können wir
              nachvollziehen, wer die Seite(n) besucht hat, und hieraus
              ableiten, wie häufig bestimmte Seiten besucht werden, welche Teile
              der Site sich besonderer Beliebtheit erfreuen. Session-Cookies
              speichern Informationen über Ihre Aktivitäten auf unserer Website.
            </li>
            <li>
              Diese Website nutzt folgende Arten von Cookies, deren Umfang und
              Funktionsweise im Folgenden erläutert werden:
              <ul>
                <li>Transiente Cookies (temporärer Einsatz)</li>
                <li>Persistente Cookies (zeitlich beschränkter Einsatz)</li>
                <li>Third party Cookies</li>
              </ul>
            </li>
            <li>
              Transiente Cookies werden automatisiert gelöscht, wenn Sie den
              Browser schließen. Dazu zählen insbesondere die Session-Cookies.
              Diese speichern eine sogenannte Session-ID, mit welcher sich
              verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
              zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden,
              wenn Sie auf die Website zurückkehren. Die Session-Cookies werden
              gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.
            </li>
            <li>
              Persistente Cookies werden automatisiert nach einer vorgegebenen
              Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
              können die Cookies in den Sicherheitseinstellungen Ihres Browsers
              jederzeit löschen.
            </li>
            <li>
              Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
              konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
              allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie
              eventuell nicht alle Funktionen dieser Website nutzen können.
            </li>
            <li>
              Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu
              können, falls Sie über einen Account bei uns verfügen. Andernfalls
              müssten Sie sich für jeden Besuch erneut einloggen.
            </li>
            <li>Es werden folgende Cookies verwendet:</li>
          </ol>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Persitenz</th>
                <th>Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Drupal.toolbar.collapsed</td>
                <td>persistent</td>
                <td>Interne Funktionalität</td>
              </tr>
              <tr>
                <td>Drupal.tableDrag.showWeight</td>
                <td>persistent</td>
                <td>Interne Funktionalität</td>
              </tr>
              <tr>
                <td>has_js</td>
                <td>persistent</td>
                <td>Interne Funktionalität</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>persistent</td>
                <td>
                  Identifikation bei Folgebesuchen (wird 24 Stunden nach dem
                  Besuch der Website gelöscht)
                </td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>persistent</td>
                <td>Identifikation bei Folgebesuchen</td>
              </tr>
            </tbody>
          </table>

          <h3>§ 6 Datenweitergabe zur Wartung der Webseite</h3>

          <ol>
            <li>
              Wir werden Ihre personenbezogenen Daten nicht an Dritte
              weitergeben, es sei denn wir informieren sie über eine Weitergabe.
            </li>
            <li>
              Unsere IT-Dienstleister besitzen Zugriff auf unsere gespeicherten
              Daten, um Fehler zu beheben und uns zu ermöglichen die geforderten
              technisch organisatorischen Maßnahmen durchzuführen. Hierbei
              berufen wir uns auf unser berechtigtes Interesse an der Sicherung
              unserer IT gem. Art. 6 Abs. 1 lit. f DSGVO bzw. auf die Erfüllung
              rechtlicher Verpflichtungen gem. Art. 6 Abs. 1 lit. b DSGVO.
            </li>
            <li>
              Der oder die IT-Dienstleister wurden von uns sorgfältig ausgewählt
              und schriftlich beauftragt. Sie sind an unsere Weisungen gebunden
              und werden von uns regelmäßig kontrolliert. Die Dienstleister
              werden diese Daten nicht an Dritte weitergeben.
            </li>
            <li>
              Eine Weitergabe ihrer Daten außerhalb des EU (EWR)-Raumes findet
              nicht statt.{" "}
            </li>
          </ol>

          <h3>§ 7 Webtracking - Google Analytics</h3>

          <ol>
            <li>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der
              Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
              Textdateien, die auf Ihrem Computer gespeichert werden und die
              eine Analyse der Benutzung der Website durch Sie ermöglichen (s. §
              5). Die durch den Cookie erzeugten Informationen über Ihre
              Benutzung dieser Website werden in der Regel an einen Server von
              Google in den USA übertragen und dort gespeichert. Im Falle der
              Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre
              IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
              Europäischen Union oder in anderen Vertragsstaaten des Abkommens
              über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Website-Aktivitäten zusammenzustellen und um weitere mit der
              Website-Nutzung und der Internetnutzung verbundene
              Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
            </li>
            <li>
              Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
              IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt.
            </li>
            <li>
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch das
              Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem sie das unter dem folgenden
              Link verfügbare Browser-Plug-in herunterladen und installieren:
              http://tools.google.com/dlpage/gaoptout?hl=de.
            </li>
            <li>
              Diese Website verwendet Google Analytics mit der Erweiterung
              „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
              weiterverarbeitet, eine Personenbeziehbarkeit kann damit
              ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
              Personenbezug zukommt, wird dieser also sofort ausgeschlossen und
              die personenbezogenen Daten damit umgehend gelöscht.
            </li>
            <li>
              Wir nutzen Google Analytics, um die Nutzung unserer Website
              analysieren und regelmäßig verbessern zu können. Über die
              gewonnenen Statistiken können wir unser Angebot verbessern und für
              Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle,
              in denen personenbezogene Daten in die USA übertragen werden, hat
              sich Google dem EU-US Privacy Shield unterworfen,
              https://www.privacyshield.gov/EU-US-Framework. Rechtsgrundlage für
              die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f
              DS-GVO.
            </li>
            <li>
              Informationen des Drittanbieters: Google Dublin, Google Ireland
              Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353
              (1) 436 1001. Nutzerbedingungen:
              http://www.google.com/analytics/terms/de.html, Übersicht zum
              Datenschutz:
              http://www.google.com/intl/de/analytics/learn/privacy.html, sowie
              die Datenschutzerklärung:
              http://www.google.de/intl/de/policies/privacy.
            </li>
          </ol>

          <h3>§ 8 Youtube</h3>

          <ol>
            <li>
              Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die
              auf http://www.YouTube.com gespeichert sind und von unserer
              Website aus direkt abspielbar sind. Diese sind alle im
              „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine
              Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie
              die Videos nicht abspielen. Erst wenn Sie die Videos abspielen,
              werden die in Abs. 2 genannten Daten übertragen. Auf diese
              Datenübertragung haben wir keinen Einfluss.
            </li>
            <li>
              Durch den Besuch auf der Website erhält YouTube die Information,
              dass Sie die entsprechende Unterseite unserer Website aufgerufen
              haben. Zudem werden die unter § 2 dieser Erklärung genannten Daten
              übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein
              Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob
              kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind,
              werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die
              Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie
              sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre
              Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung,
              Marktforschung und/oder bedarfsgerechten Gestaltung seiner
              Website. Eine solche Auswertung erfolgt insbesondere (selbst für
              nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter
              Werbung und um andere Nutzer des sozialen Netzwerks über Ihre
              Aktivitäten auf unserer Website zu informieren. Ihnen steht ein
              Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei
              Sie sich zur Ausübung dessen an YouTube richten müssen.
            </li>
            <li>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und
              ihrer Verarbeitung durch YouTube erhalten Sie in der
              Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen
              zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
              Privatsphäre: https://www.google.de/intl/de/policies/privacy.
              Google verarbeitet Ihre personenbezogenen Daten auch in den USA
              und hat sich dem EU-US-Privacy-Shield unterworfen,
              https://www.privacyshield.gov/EU-US-Framework.
            </li>
          </ol>

          <h3>§ 9 Einbindung von Google Maps</h3>

          <ol>
            <li>
              <p>
                Wir haben auf dieser Website Inhalte von Google Maps als
                Drittanbieter eingebunden. Anbieter ist die Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA (Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland)
                <br />
                Durch den Besuch auf der Website erhält der Drittanbieter die
                Information, dass Sie die entsprechende Unterseite unserer
                Website aufgerufen haben. Zudem werden die unter dem Abschnitt
                "Kategorien erhobener personenbezogener Daten" dieser Erklärung
                genannten Daten übermittelt, unter anderem auch die IP-Adresse
                zur Standortbestimmung. Dies erfolgt unabhängig davon, ob dieser
                Drittanbieter ein Nutzerkonto bereitstellt, über das Sie
                eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei
                dem Plug-in-Anbieter eingeloggt sind, werden diese Daten direkt
                Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil
                bei dem Plug-in-Anbieter nicht wünschen, müssen Sie sich vor
                Aktivierung des Buttons ausloggen.
                <br />
                Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit.
                f DSGVO. Das berechtigte Interesse besteht darin, Ihnen die
                Auffindbarkeit der (nächstgelegenen) Werkstatt so weit wie
                möglich zu vereinfachen.
                <br />
                Google LLC ist Privacy Shield zertifiziert. Die Zusammenarbeit
                mit Google LLC in datenschutzrechtlicher Hinsicht erfolgt auf
                der Grundlage eines abgeschlossenen Vertrags über die gemeinsame
                Verantwortlichkeit nach Art. 26 DSGVO, abrufbar unter
                https://privacy.google.com/intl/de/businesses/mapscontrollerterms/.
              </p>

              <p>
                Weitere Informationen zu Zweck und Umfang der Datenerhebung und
                ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in
                den im Folgenden mitgeteilten Datenschutzerklärungen dieser
                Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren
                diesbezüglichen Rechten und Einstellungsmöglichkeiten zum
                Schutze Ihrer Privatsphäre.
                https://www.google.com/policies/privacy/
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
