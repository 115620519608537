import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import Alert from "./alert";
import { HashLink as Link } from "react-router-hash-link";
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [palletCount, setPalletCount] = useState("");
  const [palletHeight, setPalletHeight] = useState("");
  const [palletWeight, setPalletWeight] = useState("");
  const [rentalDuration, setRentalDuration] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [captcha, setCaptcha] = useState("");

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // hide alert after 3 seconds

      return () => clearTimeout(timeoutId); // clear timeout on unmount or state change
    }
  }, [showAlert]);

  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_t1kzud4";
    const templateId = "template_lfyeojl";
    const userId = "T9qfySI4tfOH_m42H";

    const templateParams = {
      name,
      email,
      company,
      phone,
      palletCount,
      palletHeight,
      palletWeight,
      rentalDuration,
    };

    emailjs.send(serviceId, templateId, templateParams, userId).then(() => {
      // show success alert
      setShowAlert(true);
      setAlertType("success");
      setAlertMessage("Ihre Anfrage wurde erfolgreich versendet!");
      // reset form inputs
      setName("");
      setEmail("");
      setCompany("");
      setPhone("");
      setPalletCount("");
      setPalletHeight("");
      setPalletWeight("");
      setRentalDuration("");
      setTosAccepted(false);
    });
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Anfrage</h2>
                <p>
                  Wenn Sie Fragen zu unseren Leistungen und Angeboten haben,{" "}
                  <br /> können Sie das folgende Formular nutzen!
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name">
                    {" "}
                    <p>Name:</p>{" "}
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="company">
                    {" "}
                    <p>Firma:</p>{" "}
                  </label>
                  <input
                    type="text"
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    {" "}
                    <p>E-Mail:</p>{" "}
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phone">
                    <p>Tel:</p>
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="palletCount">
                    <p>Anzahl Paletten:</p>
                  </label>
                  <input
                    type="number"
                    id="palletCount"
                    value={palletCount}
                    onChange={(e) => setPalletCount(e.target.value)}
                    min="0"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="palletHeight">
                    <p>Höhe Paletten (cm):</p>
                  </label>
                  <input
                    type="number"
                    id="palletHeight"
                    value={palletHeight}
                    onChange={(e) => setPalletHeight(e.target.value)}
                    min="0"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="palletWeight">
                    <p>Gewicht Paletten (Kg):</p>
                  </label>
                  <input
                    type="number"
                    id="palletWeight"
                    value={palletWeight}
                    onChange={(e) => setPalletWeight(e.target.value)}
                    min="0"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="rentalDuration">
                    <p>Geplante Mietdauer:</p>
                  </label>
                  <input
                    type="text"
                    id="rentalDuration"
                    value={rentalDuration}
                    onChange={(e) => setRentalDuration(e.target.value)}
                    required
                  />
                </div>
                <div className="checkboxbtn">
                  <div>
                    <label className="checkboxtext">
                      <p className="checkboxtextparagraph">Hiermit akzeptiere ich die <Link
                        to="/datenschutzerklaerung"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "underline" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                         Datenschutzerklärung.
                      </Link>{" "}<input
                        type="checkbox"
                        name="tos"
                        checked={tosAccepted}
                        onChange={(e) => setTosAccepted(e.target.checked)}
                        required
                      /></p>
                      
                      
                    </label>
                  </div>

                  
                    <ReCAPTCHA
                      className="captcha"
                      sitekey="6LeGOfclAAAAAItyI4Z8WEH7naoanF9WhxVR2LKL"
                      onChange={handleCaptchaChange}
                    />

                    <button
                      className="btn btn-custom btn-lg"
                      type="submit"
                      disabled={captcha === ""}
                    >
                      Senden
                    </button>
                  
                </div>

                {showAlert && <Alert type={alertType} message={alertMessage} />}
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info section-title">
            <h2>Impressum</h2>

            <div className="contact-item">
              <span>
                <i className="fa fa-map-marker"></i> Adresse (Verwaltung)
              </span>
              <p>
                PalettenHostel
                <br />
                Inh. Z. Erdogmus
                <br />
                Alexander-Bell-Straße 3 <br /> 53332 Bornheim
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefonnumer
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-clock-o"></i> Öffnungszeiten
                </span>{" "}
                {props.data ? props.data.öffnungszeiten : "loading"}
              </p>
            </div>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.8081357269007!2d7.023622496350079!3d50.76059119856296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf1fb517364ca5%3A0xa3024d65c9ae5081!2sAlexander-Bell-Stra%C3%9Fe%203%2C%2053332%20Bornheim%2C%20Germany!5e0!3m2!1sen!2sus!4v1683585771075!5m2!1sen!2sus"></iframe>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {new Date().getFullYear()} PalettenHostel. Interesse an einer
            Website: i.erdogmus@web.de.
          </p>
        </div>
      </div>
    </div>
  );
};
