import { useState, useEffect } from "react";

import JsonData from "../data/data.json";

import { About } from '../components/about'
import { Features } from '../components/features'
import { Header } from '../components/header'
import { Services } from '../components/services'
import { Team } from '../components/Team'
import { Testimonials } from '../components/testimonials'
import { PriceTable } from '../components/pricetable'
import { motion } from "framer-motion/dist/framer-motion"
import { Contact } from "../components/contact";


const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <motion.div 
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    transition={{ duration: 1 }}
    > 
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <PriceTable data={landingPageData.PriceTable} />
      {/*<Services data={landingPageData.Services} />*/}
      {/*<About data={landingPageData.About} />*/}
      {/*<Testimonials data={landingPageData.Testimonials} />*/}
      {/*<Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} />
    </motion.div>
  )
}

export default Home